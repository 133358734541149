import React, { Component } from 'react';
import ModalImage from 'react-image-modal'
import { Tweet } from '.';

export default class Selection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            childrenIsOpen: false,
            main_tweet_image: null,
        }
    }
   
    render() {
        const { selections, heure_pub, isOpen } = this.props
        const { childrenIsOpen, main_tweet_image } = this.state
        const main_tweet = selections[0];
        // selections.splice(0, 1);

        if (main_tweet) {
            const twitter_url = main_tweet.custom_fields._selection_twitter_url[0]
            return (
                <div className={`selection ${isOpen ? "open" : "close"}`}>
                    <div className='main-tweet'>
                        <Tweet tweet={main_tweet} isPrimary setMainTweetImage={this.setMainTweetImage} />
                    </div>
                    
                    <div className='children'>
                    
                        {(selections && selections.length > 0) ?
                            <div>
                                <div className={`related_tweets ${childrenIsOpen ? "open" : "close"}`}>
                                    {
                                        selections.map((child_tweet, j) =>
                                            <Tweet tweet={child_tweet} key={`subtweet-${j}`} />
                                        )
                                    }
                                </div>
                                <button className='show-related-btn' onClick={this.setChildrenOpen}>{childrenIsOpen ? "-" : "+"}</button>
                            </div>
                            :
                            twitter_url.length > 0 ?
                                <div>
                                    <a className='show-related-btn' href={twitter_url}>+</a>
                                </div>
                                :
                                <div className='end-of-selection'>

                                </div>
                        }

                    </div>
                </div>
            );
        }
        else
        {
            return(<div className={`selection ${isOpen ? "open" : "close"}`}></div>)
        }
    }
}
