import React, { Component } from 'react';
import ModalImage from 'react-image-modal'
export default class Tweet extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tweet: props.tweet,
            author: null,
            profil_image: null,
            featured_image: null,
            parent_tweet: null,
            children_tweets: null,
        }
    }
    componentDidMount = () => {
        this.initTweet(this.props.tweet)
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.tweet !== this.props.tweet) {
            this.initTweet(this.props.tweet)
        }
    }
    initTweet = (tweet) => {
        this.setState({ featured_image: this.props.images[tweet.featured_media] ? this.props.images[tweet.featured_media] : null })
        this.setState({ author: this.props.authors[tweet.custom_fields._selection_author_id[0]] ? this.props.authors[tweet.custom_fields._selection_author_id[0]] : null },
            () => this.state.author && this.setState({ profil_image: this.props.images[this.state.author.featured_media] })
        )
    }

    render() {
        const { tweet } = this.props
        let { author, profil_image, featured_image } = this.state
        let { title, excerpt, custom_fields: { _selection_origin_id } } = tweet
        return (
            <div className="tweet">
                {excerpt.rendered.length > 0 &&
                    <a href={_selection_origin_id} target='_blank'><div className='tweet-content' dangerouslySetInnerHTML={{ __html: excerpt.rendered }} /></a>
                }

                {featured_image &&
                    <ModalImage
                        showDownload={true}
                        showRotation={true}
                        showZoom={true}
                        src={featured_image.guid.rendered}
                        alt={featured_image.title.rendered}
                        className='featured-image'
                    />
                }
                {author &&
                    <div className='author-infos'>
                        {profil_image &&

                            <img className="profil-image" src={profil_image.guid.rendered} alt={profil_image.title.rendered} />
                        }
                        <div className='author-infos-name'>
                            <a href={author.link} target="_blank">
                                <h4 className='author-name' dangerouslySetInnerHTML={{ __html: author.title.rendered }}></h4>
                                <h5 className='twitter-handle' dangerouslySetInnerHTML={{ __html: tweet.custom_fields._selection_twitter_handle[0] }}></h5>
                            </a>
                        </div>
                    </div>
                }


            </div>
        );
    }
}
