import React, { Component } from 'react';
import { Tweet, TweetLink } from '.';

export default class SelectionsByTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image_selections: [],
            text_selections: [],
            link_selections: [],
        }
    }
    openTweet = () => {
        this.props.setOpennedTime(this.props.heure_pub)
        setTimeout(this.setHeightOfStyles, 1000)
    }
    setHeightOfStyles = () => {
        const current_selections = document.getElementById(`selection-${this.props.heure_pub}`)
        const topContent = current_selections.querySelector('.top-section');
        const bottomContent = current_selections.querySelector('.bottom-section');

        let selectionsListHeight = "100vh"
        // if (topContent.offsetHeight > bottomContent.offsetHeight) 
        selectionsListHeight = (topContent.offsetHeight * 2) + 50;
        //else if (topContent.offsetHeight <= bottomContent.offsetHeight) selectionsListHeight = bottomContent.offsetHeight * 2;
        if (selectionsListHeight !== "100vh")
            selectionsListHeight = `${selectionsListHeight}px`;
        this.props.setListHeight(selectionsListHeight)
    }

    closeTweet = () => {
        this.props.closeTime()
    }
    componentDidMount = () => {
        // this.regroupSelections()
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.regroupSelections()
        }
    }
    regroupSelections = () => {
        const { selections } = this.props
        const regroupped_selections = selections.reduce(function (array, x) {
            let field = ""
            if (x.custom_fields._selection_twitter_url[0].length > 0) {
                field = "link"
            }
            else if (x.featured_media > 0) {
                field = "image"
            }
            else {
                field = "text"
            }
            (array[field] = array[field] || []).push(x);
            return array;
        }, {});
        this.setState({
            image_selections: regroupped_selections["image"] ? regroupped_selections["image"] : [],
            link_selections: regroupped_selections["link"] ? regroupped_selections["link"] : [],
            text_selections: regroupped_selections["text"] ? regroupped_selections["text"] : [],
        })
    }

    render() {
        const { heure_pub, isOpen, images, authors } = this.props
        const { image_selections, text_selections, link_selections } = this.state
        return (
            <div id={`selection-${heure_pub}`} className={`selection ${isOpen ? 'open' : 'close'}`}>
                <div className='top-section'>
                    {text_selections.map((tweet) =>
                        <Tweet tweet={tweet} images={images} authors={authors} />
                    )}
                </div>
                <div className='selection-time' onClick={isOpen ? this.closeTweet : this.openTweet}>
                    {heure_pub}
                </div>
                <div className='bottom-section'>
                    {
                        image_selections.length > 0 &&
                        <div className='selections-images'>
                            {image_selections.map((tweet) =>
                                <Tweet tweet={tweet} images={images} authors={authors} />
                            )}
                        </div>
                    }
                    {
                        link_selections.length > 0 &&
                        <div className='selections-links'>
                            {link_selections.map((tweet) =>
                                <TweetLink tweet={tweet} />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
