import React, { Component } from 'react';
import { Selection } from '.';
import { getAuthors, getMedias, getSelections } from '../Api';
import '../styles/Tweets.css'
import SelectionsByTime from './SelectionsByTime';

export default class SelectionsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selection_grouped: [],
            authors: {},
            images: {},
            opennedTweetId: null,
            listHeight: "100vh",
        }
    }
    componentDidMount = () => {
        getSelections().then((selections) => {
            selections.sort(this.compare_heure_pub)
            const selection_grouped = selections.reduce(function (array, x) {
                (array[x.custom_fields._selection_heure_pub[0]] = array[x.custom_fields._selection_heure_pub[0]] || []).push(x);
                return array;
            }, {});
            this.setState({ selection_grouped })
        });
        getAuthors().then(authors => {
            const authors_obj = authors.reduce((o, author) => ({ ...o, [author.id]: author }), {})
            this.setState({ authors: authors_obj })
        })
        getMedias().then(images => {
            const images_obj = images.reduce((o, image) => ({ ...o, [image.id]: image }), {})
            this.setState({ images: images_obj })
        })
    }

    setOpennedTime = (heure_pub) => {
        this.setState({ opennedTime: heure_pub })
    }
    closeTime = () => {
        this.setState({ opennedTime: null, listHeight: "100vh" }, this.setToTopOfScreen)
    }
    compare_heure_pub = (a, b) => {
        if (a.custom_fields._selection_heure_pub < b.custom_fields._selection_heure_pub)
            return -1;
        if (a.custom_fields._selection_heure_pub > b.custom_fields._selection_heure_pub)
            return 1;
        return 0;
    }
    setListHeight = (height) => {
        this.setState({ listHeight: height }, this.setToTopOfScreen);
    }
    setToTopOfScreen = () => {
        // setTimeout(() =>
        let height = document.querySelector('.selections-list').style.minHeight
        height = parseInt(height.replace("px", ""))
        const windowHeight = window.innerHeight
        window.scrollTo({ top: (height / 2) - (windowHeight / 2), behavior: 'auto' })
        // , 600)
    }
    render() {
        const { selection_grouped, images, authors, opennedTime, listHeight } = this.state
        return (
            <div className='selections-list' style={{ minHeight: listHeight }}>
                {Object.keys(selection_grouped).map(heure_pub =>
                    <SelectionsByTime setListHeight={this.setListHeight} selections={selection_grouped[heure_pub]} images={images} authors={authors} heure_pub={heure_pub} isOpen={opennedTime && heure_pub === opennedTime} closeTime={this.closeTime} setOpennedTime={this.setOpennedTime} key={`selections-${heure_pub}`} />
                )}
            </div>
        );
    }
}
