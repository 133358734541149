import './styles/App.css';
import { Menu, SelectionsList } from './Components';

function App() {
  return (
    <div className="App">
      <SelectionsList />
      <Menu />
    </div>
  );
}

export default App;
