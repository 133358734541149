import React, { Component } from 'react';

export default class TweetLink extends Component {
    render() {
        const { tweet } = this.props
        const { custom_fields: { _selection_twitter_url } } = tweet
        return (
            <div className="tweet link-tweet">
                <a className='show-related-btn' href={_selection_twitter_url} target="_blank">
                    +
                </a>
            </div>
        );
    }
}
